@tailwind base;
@tailwind components;
@tailwind utilities;

body{

    font-family: Arial;
    font-size:13px;
    overflow:hidden
}

.leaflet-container {
    width: 98vw;
    height: 57vh;
    margin-left: 1vw;
    margin-right: 1vw;
}

/* @media only screen and (min-width: 768px) {
    .leaflet-container {
        width: 47vw;
        height: 43vh;
        margin: 0px;
    }
} */

@media only screen and (min-width: 768px) {
    .leaflet-container {
        width: 100%;
        height: 100%;
        margin: 0px;
    }
}

.title {
    background: linear-gradient(0deg, rgba(39,51,77,1) 0%, rgba(49,61,87,1) 100%);
}
